import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import NavBar from "../../components/NavBar";
import "../../style/global.css";
import api from "../../services/api";
import "./style.css";
import Modal from "react-modal";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadIcon from "@mui/icons-material/Download";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Button } from "@mui/material";

// mensagens de erro
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { accessValidator } from '../../util/validationProfile';
import '../../style/global.css';
import { Margin } from '@mui/icons-material';

function CnabConverter() {
  let history = useHistory();
  const pageSize = 10
  const [customLoader, setCustomLoader] = useState(false);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [files, setFiles] = useState([]);

  const validate = () => {
    const verify = accessValidator('cnabConverter');

    if (verify === false) {
      toast.info("Seu usuário não tem permissões para esse sistema, contate o suporte")
      history.push('/home');
    }
  }

  const getCnabList = async (page) => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);

      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const response = await api.get(`api/suporte/cnab`, {
        headers: { Authorization: "Bearer " + accessToken },
        params: {
          page: page ?? currentPage,
          pageSize: pageSize,
        }
      });

      if (response.data.error === true) {
        toast.error(response.data.message);

        return;
      }

      setTotalPage(response.data.totalPages)
      if (response.data.totalPages > 1) {
        setCanNextPage(true)
      }
      setFiles(response.data.list);
      setCustomLoader(false)
    } catch (error) {
      console.error(error);

      toast.error("Erro ao listar CNABs convertidos!");
    }
  }

  useEffect(() => {
    getCnabList(null);
    validate();
  }, []);

  const gotoPage = (page) => {
    if (page < 1 || page > totalPage) {
      return;
    }

    setCustomLoader(true)
    setPage(page)

    setCurrentPage(page);

    getCnabList(page)
    setCanPreviousPage(page > 1);
    setCanNextPage(page < totalPage);


  };

  const onClickButtonFooter = () => {
    return (
      <div></div>
    )
  }

  const headerRender = () => {
    return (
      <div>

      </div>
    )
  }

  const toggleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedFiles(files.map((file) => file.id));
    } else {
      setSelectedFiles([]);
    }
  };

  const toggleSelectFile = (id) => {
    if (selectedFiles.includes(id)) {
      setSelectedFiles(selectedFiles.filter((fileId) => fileId !== id));
    } else {
      setSelectedFiles([...selectedFiles, id]);
    }
  };

  const handleImport = async () => {
    console.log("aaa")
  }

  const handleDownload = async (fileName) => {
    setCustomLoader(true);

    try {
      const response = await api.get(`api/suporte/cnab/download/${fileName}`, {
        responseType: "blob", // Importante para tratar o arquivo como Blob
      });

      // Obter o blob do arquivo
      const blob = response.data;

      // Criar uma URL temporária e iniciar o download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName; // Nome do arquivo no download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Limpar a URL temporária
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Erro no download:", error);
      alert("Falha ao baixar o arquivo. Tente novamente.");
    } finally {
      // Remover o loader após o processo
      setCustomLoader(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString + 'z');
    return new Intl.DateTimeFormat('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(date);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  // Abrir o modal
  const openModal = () => setIsModalOpen(true);

  // Fechar o modal
  const closeModal = () => setIsModalOpen(false);

  // Capturar o arquivo selecionado
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      alert("Por favor, selecione um arquivo.");
      return;
    }

    setCustomLoader(true);
    // Criar o formData e adicionar o arquivo
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      // Fazer a requisição para a rota API
      const response = await api.post("/api/suporte/cnab/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Exibir sucesso
      console.log("Resposta da API:", response.data);
      alert("Arquivo enviado com sucesso!");
    } catch (error) {
      // Exibir erro
      console.error("Erro ao enviar o arquivo:", error);
      alert("Erro ao enviar o arquivo. Por favor, tente novamente.");
    } finally {
      closeModal(); // Fechar o modal após a tentativa
      getCnabList(page);
      setCustomLoader(false);
    }
  };

  return (
    <section>
      <div style={{ height: '3px' }}>
        <div className={customLoader === true ? "BCS-divisor-loader" : ""} />
      </div>
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-12 col-md-12 col-lg-10 col-xl-10 text-center ">
            <div className="col">
              <div style={styles.header}>
                <p className="text-title-Appito justify-content-start pt-4 tracking-in-expand ">
                  Conversor de CNAB
                </p>
                <Button
                  variant="contained"
                  startIcon={<UploadFileIcon />}
                  onClick={openModal}
                >
                  Importar
                </Button>
              </div>
              <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Importar arquivo"
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  },
                  content: {
                    width: "65%", // Defina a largura desejada
                    height: "200px", // Defina a altura desejada
                    margin: "auto",
                    borderRadius: "10px",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                }}
                ariaHideApp={false} // Necessário apenas para evitar avisos em testes locais
              >
                <h2>Importar arquivo</h2>
                <input type="file" onChange={handleFileChange} />
                <div style={{ marginTop: "20px" }}>
                  <button onClick={closeModal} >
                    Cancelar
                  </button>
                  <button style={{ marginLeft: "10px" }} onClick={handleSubmit}>Enviar</button>
                </div>
              </Modal>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      {/* <th style={{ ...styles.th, textAlign: 'center' }}>
                        <input
                          type="checkbox"
                          onChange={toggleSelectAll}
                          checked={selectedFiles.length === files.length}
                        />
                      </th> */}
                      <th style={styles.th}>Nome do Arquivo</th>
                      <th style={{ ...styles.th, maxWidth: "50px", textAlign: "center" }}>Data de Criação</th>
                      <th style={{ ...styles.th, maxWidth: "50px", textAlign: "center" }}>Ação</th>
                    </tr>
                  </thead>
                  {files.length >= 1 && <tbody>
                    {files.map((file) => (
                      <tr key={file.id} style={styles.tr}>
                        {/* <td style={{ ...styles.td, textAlign: 'center' }}>
                          <input
                            type="checkbox"
                            checked={selectedFiles.includes(file.id)}
                            onChange={() => toggleSelectFile(file.id)}
                          />
                        </td> */}
                        <td style={{ ...styles.td, maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{file.originalFileName}</td>
                        <td style={{ ...styles.td, maxWidth: "50px", textAlign: "center" }}>{formatDate(file.createdAt)}</td>
                        <td style={{ ...styles.td, maxWidth: "70px", textAlign: "center" }}>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button
                              variant="contained"
                              startIcon={<DownloadIcon />}
                              onClick={() => handleDownload(file.inputName)}
                            >
                              INPUT
                            </Button>

                            <Button
                              variant="contained"
                              startIcon={<DownloadIcon />}
                              onClick={() => handleDownload(file.outputName)}
                            >
                              REMESSA
                            </Button>
                          </div>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                  }

                </table>
                {files.length >= 1 && <div>
                  <button onClick={() => gotoPage(1)} disabled={!canPreviousPage}>
                    {'<<'}
                  </button>{' '}
                  <button onClick={() => gotoPage(currentPage - 1)} disabled={!canPreviousPage}>
                    {'<'}
                  </button>{' '}
                  <span>
                    Página {currentPage} de {totalPage}
                  </span>{' '}
                  <button onClick={() => gotoPage(currentPage + 1)} disabled={!canNextPage}>
                    {'>'}
                  </button>{' '}
                  <button onClick={() => gotoPage(totalPage)} disabled={!canNextPage}>
                    {'>>'}
                  </button>{' '}
                </div>
                }
                {files.length === 0 && <h5>
                  Nenhum registro encontrado!
                </h5>
                }
                {/* {files.length >= 1 && <div style={{}}>
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={() => handleDownload()}
                  >
                    Baixar
                  </Button>
                </div>
                } */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CnabConverter;

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  th: {
    backgroundColor: "#f4f4f4",
    padding: "8px",
    border: "1px solid #ddd",
    textAlign: "left",
  },
  td: {
    padding: "8px",
    border: "1px solid #ddd",
    textAlign: "left",
  },
  tr: {
    cursor: "pointer",
    backgroundColor: "#fff",
  },
  button: {
    padding: "5px 10px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "3px",
    cursor: "pointer",
  },
};