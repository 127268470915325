import React, { useEffect } from "react";

import "./style.css";

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    const truncatedText = text.substring(0, maxLength).trim();
    const lastSpaceIndex = truncatedText.lastIndexOf(" ");

    return truncatedText.substring(0, lastSpaceIndex) + "...";
  }

  return text;
}

function CustomItemsList({ item, status, onClickCheck }) {
  useEffect(() => {}, []);

  return (
    <div onClick={onClickCheck} className="row custom-item">
      {/* ID */}
      <div className="col-md-auto">
        <p className="text-start items-text-truncate">{item.id}</p>
      </div>

      {/* Item Name */}
      <div className="col">
        <div className="text-start items-text-truncate">
          {truncateText(item.name, 42)}
        </div>
        <div className="text-start sub-info-item">
          {item.timeRangeDesc} | qtd. {item.quantity}
        </div>
      </div>

      {/* Price */}
      <div className="col">
        <div className="text-start items-text-truncate">{item.priceDesc}</div>
      </div>

      {/* Billing Type */}
      <div className="col">
        <div className="text-start items-text-truncate">
          {item.billingTypeDesc}
        </div>
      </div>

      {/* Status */}
      <div className="col-md-auto">
        {status === "1" && <div className="item-active">Ativo</div>}
        {status === "0" && <div className="item-inactive">Inativo</div>}
      </div>

      {/* Divider */}
      <div className="items-divisor"></div>
    </div>
  );
}

export default CustomItemsList;
