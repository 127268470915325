import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

import BaseScreen from "../pages/BaseScreen";
import Header from "../components/Header/index";
import Home from "../pages/Home/index";
import Login from "../pages/Login";
import Profile from "../pages/Profile/index";
import ProfileBusiness from "../pages/ProfileBusiness/index";
import Plan from "../pages/Plan";
import Copa from "../pages/Copa";
import Sms from "../pages/Sms";
// import FootballSchool from '../pages/FootballSchool';
import Billing from "../pages/Billing";
import MonthlyBilling from "../pages/MonthlyBilling";
import CustomizedItems from "../pages/CustomizedItems";
import Coupons from "../pages/Coupons";
import NewCoupon from "../pages/NewCoupon";
import CnabConverter from "../pages/CnabConverter";

const ROUTES = {
  BASE: "/base",

  LOGIN: "/",
  HOME: "/home",
  PROFILE: "/profile",
  PROFILEBUSINESS: "/profileBusiness",
  PLAN: "/plan",
  COPA: "/copa",
  SMS: "/sms",
  CNABCONVERTER: "/cnabConverter",
  MONTHLYBILLING: "/monthlyBilling",
  CUSTOMIZEDITEMS: "/customizedItems",
  //FOOTBALLSCHOOL: "/school",
  BILLING: "/billing",
  COUPONS: "/coupons",
  NEWCOUPON: "/newCoupon",
};

const HeaderPage = () => {
  const location = useLocation();

  if (location.pathname === "/home") return;

  return (
    <div
      style={{
        width: "100%",
        height: 90,
        background: "red",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div className="container ">
        <div className="row justify-content-center ">
          <div className="col-12 col-md-12 col-lg-10 col-xl-10 text-center ">
            <div className="col">
              <div
                style={{ background: "purple", width: "100%", height: "100%" }}
              >
                Header Teste
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FooterPage = () => {
  return (
    <div
      style={{
        width: "100%",
        height: 90,
        background: "red",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div className="container ">
        <div className="row justify-content-center ">
          <div className="col-12 col-md-12 col-lg-10 col-xl-10 text-center ">
            <div className="col">
              <div
                style={{ background: "purple", width: "100%", height: "100%" }}
              >
                Footer
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      //Add a token validation
      localStorage.getItem("token") ? (
        <Header>
          <div
            style={{
              height: "calc(100vh - 60px)",
              // background: "blue",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                // background: "blue",
              }}
            >
              <Component {...props} />
            </div>
          </div>
        </Header>
      ) : (
        <Redirect to={{ pathname: ROUTES.LOGIN }} />
      )
    }
  />
);

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={ROUTES.LOGIN} exact component={Login} />
        <PrivateRoute path={ROUTES.BASE} component={BaseScreen} />
        <PrivateRoute path={ROUTES.HOME} component={Home} />
        <PrivateRoute path={ROUTES.PROFILE} component={Profile} />
        <PrivateRoute path={ROUTES.COPA} component={Copa} />
        <PrivateRoute path={ROUTES.SMS} component={Sms} />
        <PrivateRoute path={ROUTES.CNABCONVERTER} component={CnabConverter} />
        <PrivateRoute path={ROUTES.CUSTOMIZEDITEMS} component={CustomizedItems} />
        {/* <PrivateRoute path={ROUTES.FOOTBALLSCHOOL} component={FootballSchool} />*/}
        <PrivateRoute path={ROUTES.MONTHLYBILLING} component={MonthlyBilling} />
        <PrivateRoute path={ROUTES.BILLING} component={Billing} />
        <PrivateRoute path={ROUTES.COUPONS} component={Coupons} />
        <PrivateRoute path={ROUTES.NEWCOUPON} component={NewCoupon} />

        <PrivateRoute path={ROUTES.PLAN} component={Plan} />
        <PrivateRoute
          path={ROUTES.PROFILEBUSINESS}
          component={ProfileBusiness}
        />
      </Switch>
    </BrowserRouter>
  );
}
