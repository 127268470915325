import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "../../style/global.css";
import api from "../../services/api";
import NavBar from "../../components/NavBar";
import iconLupaInfo from "../../assets/icons/coupons/iconInfo.svg";
import CustomItemsList from "../../components/CustomItemsList";
import CustomItemModal from "../../components/CustomItemModal";
import "./style.css";

function CustomizedItems() {
  // PAGINAÇÃO
  let currentPageSend = "";
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showPagination, setShowPagination] = useState(false);
  const [showPositionTwo, setShowPositionTwo] = useState(false);
  const [showPositionThree, setShowPositionThree] = useState(false);
  const [positionOne, setPositionOne] = useState(1);
  const [positionTwo, setPositionTwo] = useState(2);
  const [positionThree, setPositionThree] = useState(3);
  const [firstRowNum, setFirstRowNum] = useState();
  const [lastRowNum, setLastRowNum] = useState();
  const [totalRowNum, setTotalRowNum] = useState();
  const [idToUpdate, setIdToUpdate] = useState(null);

  const [customItems, setCustomItems] = useState([]);
  const [customLoader, setCustomLoader] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [statusList, setStatusList] = useState([]);
  const [status, setStatus] = useState('1');
  const [sendActive, setSendActive] = useState(true);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  function handleCloseModal() {
    setShowModal(false)
    setIdToUpdate(null)

    resetPagination()
    getCustomItems()
  };

  const onClickButtonFooter = async () => {
    handleOpenModal()
  };

  const createUrl = (statusToUse) => {
    let url = "";

    if (statusToUse !== "" && statusToUse !== undefined) {
      if (url.length > 0) {
        url += "&active=" + (statusToUse === "0" ? "false" : "true");
      } else {
        url += "?active=" + (statusToUse === "0" ? "false" : "true");
      }
    }

    let getCurrentPage = currentPageSend || currentPage;
    if (
      getCurrentPage !== "" &&
      getCurrentPage !== undefined &&
      getCurrentPage != 0
    ) {
      if (url.length > 0) {
        url += "&pageNumber=" + getCurrentPage;
      } else {
        url += "?pageNumber=" + getCurrentPage;
      }
    }

    return url;
  };

  const getCustomItems = async (currentStatus) => {
    try {
      setCustomLoader(true);

      const statusToUse = currentStatus !== undefined ? currentStatus : status;
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);
      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const url = createUrl(statusToUse);

      const response = await api.get(`api/suporte/GetCustomItems${url}`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      if (response.data.items.length === 0) {
        setCustomItems([]);
      } else {
        setCustomItems([]); 

        const totalPages = response.data?.totalPages || 0;
        const itemsOnPage = response.data?.items?.length || 0; 
        const totalCount = response.data?.totalCount || 0;

        const registrosPorPagina = 10;

        const getCurrentPage = currentPageSend || currentPage;
        const firstRow = (getCurrentPage - 1) * registrosPorPagina + 1;
        const lastRow = Math.min(getCurrentPage * registrosPorPagina, totalCount);

        setFirstRowNum(firstRow);
        setLastRowNum(lastRow);
        setTotalRowNum(totalCount); 
        setCustomItems(response.data?.items);
        setTotalPages(totalPages);

        if (totalPages === 2) {
          setShowPositionTwo(true);
        } else if (totalPages === 3) {
          setShowPositionThree(true);
        }
        
        if (totalPages > 1) {
          setShowPagination(true);
        }
        
      }

      if (response.data.error === true) {
        toast.error(response.data.message);

        setCustomLoader();

        return;
      }

      setCustomLoader();
    } catch (error) {
      console.error(error);

      toast.error("Erro ao buscar itens customizados");
    }
  };
  
  const changePosition = (position) => {
    setCurrentPage(position);
    currentPageSend = position;
    getCustomItems();
  };

  const onClickPositionThree = (position) => {
    if (totalPages > position) {
      const newPosition = positionThree + 1;
      setPositionOne(positionTwo);
      setPositionTwo(positionThree);
      setPositionThree(newPosition);
      changePosition(position);
    }

    if (totalPages === position) {
      changePosition(position);
    }
  };

  const onClickPositionOne = (position) => {
    if (position > 1) {
      const newPosition = positionOne - 1;
      setPositionOne(newPosition);
      setPositionTwo(positionOne);
      setPositionThree(positionTwo);
      changePosition(position);
    }
    if (position === 1) {
      setPositionOne(1);
      setPositionTwo(2);
      setPositionThree(3);
      changePosition(position);
    }
  };

  const onClickPrevious = () => {
    if (currentPage === 1) {
      return;
    }

    if (currentPage === totalPages) {
      changePosition(currentPage - 1);
      return;
    }

    if (currentPage > 2) {
      changePosition(currentPage - 1);
      setPositionOne(positionOne - 1);
      setPositionTwo(positionTwo - 1);
      setPositionThree(positionThree - 1);
      return;
    }

    if (currentPage === 2) {
      changePosition(currentPage - 1);
      return;
    }
  };

  const onClickNext = () => {
    if (currentPage === totalPages) {
      return;
    }

    if (currentPage === 1) {
      changePosition(currentPage + 1);
      return;
    }

    if (currentPage === totalPages - 1) {
      changePosition(currentPage + 1);
      return;
    }

    if (currentPage < totalPages) {
      changePosition(currentPage + 1);
      setPositionOne(positionOne + 1);
      setPositionTwo(positionTwo + 1);
      setPositionThree(positionThree + 1);
      return;
    }
  };

  const resetPagination = () => {
    setCurrentPage(1);
    currentPageSend = 1;
    setShowPagination(false);
    setShowPositionTwo(false);
    setShowPositionThree(false);
    setPositionOne(1);
    setPositionTwo(2);
    setPositionThree(3);
  };

  const changeStatus = (statusId) => {
    setStatus(statusId)
    setCustomItems([])
    resetPagination();
    getCustomItems(statusId);
  };

  const headerRender = () => {
    return (
      <>
        {showHeader ? (
          <div className="show-header">
            <div className="row mb-3">
              <div className="status-list">
                <div className="col">
                  <select
                    type="text"
                    className={`form-control form-select scale-in-center BCS-select`}
                    placeholder="Status"
                    value={status}
                    onChange={(event) => changeStatus(event.target.value)}
                  >
                    {statusList.map((status, index) => (
                      <option key={index} value={status.id}>
                        {status.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

              <div className="row">
                <div className="col-md-auto">#</div>
                <div className="col">Item</div>
                <div className="col">Valor de venda</div>
                <div className="col">Faturamento</div>
                <div className="col-md-auto">Status</div>
              </div>

              <div
              className={customLoader === true ? "BCS-divisor-loader mt-4" : ""}
            />
          </div>
        ) : (
          <div className="row">
            <div className={"BCS-divisor-loader"} />
          </div>
        )}
      </>
    );
  };

  const clickChecked = (id) => {
    setIdToUpdate(id)
    handleOpenModal()
  };

  const ShowHeader = (bool = false) => {
    return setShowHeader(bool);
  };

  useEffect(() => {
    setStatusList([{
      id: '1',
      name: 'Ativo'
    }, {
      id: '0',
      name: 'Inativo'
    }])

    ShowHeader(true)
    getCustomItems();
  }, []);

  return (
    <>
      <CustomItemModal
        data={{
          idToUpdate
        }}
        show={showModal}
        onClose={handleCloseModal}
      />
      <NavBar
        headerRender={headerRender}
        activeButton={sendActive}
        hiddenFooter={showHeader}
        textButtonFooter={"Criar Item"}
        onClickButtonFooter={onClickButtonFooter}
      >
        <section>
          <div className="container ">
            <div className="row justify-content-center ">
              <div className="col-12 col-md-12 col-lg-10 col-xl-10 text-center ">
                <div className="mt-2">
                  {
                    customItems.length ? customItems.map((item) => (
                      <CustomItemsList
                        key={item.id}
                        item={item}
                        status={status}
                        onClickCheck={() =>
                          clickChecked(item.id)
                        }
                      />
                    )) : <div></div>
                  }
                  {
                    !customItems.length && !customLoader ? <div className="mt-4">
                    <div>
                      <img src={iconLupaInfo} alt="logo" />
                    </div>
                    <div className="coupon-info-title mt-2">
                      Nenhum registro encontrado
                    </div>
                    <div className="coupon-info-subtitle">
                      Tente novamente com outros filtros.
                    </div>
                  </div> : <div></div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-12 col-md-12 col-lg-10 col-xl-10 text-center ">
                <div className="mt-2">
                  {showPagination === true ? (
                    <div className="pagination-organize">
                      <div className="pagination-info">
                        <div>
                          Exibindo {firstRowNum}-{lastRowNum} de {totalRowNum}
                        </div>
                      </div>
                      <div className="pagination">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination">
                            <li className="page-item">
                              <div
                                className="page-link pagination-number "
                                href="#"
                                aria-label="Previous"
                                onClick={() => onClickPrevious()}
                                style={{ borderRadius: "20px 0 0 20px " }}
                              >
                                <span aria-hidden="true">
                                  <i className="bi bi-chevron-compact-left"></i>
                                </span>
                              </div>
                            </li>
                            <li className="page-item">
                              <p
                                className={`page-link ${
                                  currentPage === positionOne
                                    ? "pagination-number-choice"
                                    : "pagination-number"
                                } `}
                                onClick={() =>
                                  onClickPositionOne(positionOne)
                                }
                              >
                                {positionOne}
                              </p>
                            </li>

                            { showPositionTwo && <li>
                                <p
                                  className={`page-link ${
                                    currentPage === positionTwo
                                      ? "pagination-number-choice"
                                      : "pagination-number"
                                  } `}
                                  onClick={() => changePosition(positionTwo)}
                                >
                                  {positionTwo}
                                </p>
                              </li> 
                            }
                            { showPositionThree && <li className="page-item">
                                <p
                                  className={`page-link ${
                                    currentPage === positionThree
                                      ? "pagination-number-choice"
                                      : "pagination-number"
                                  } `}
                                  onClick={() =>
                                    onClickPositionThree(positionThree)
                                  }
                                >
                                  {positionThree}
                                </p>
                              </li>
                            }

                            <li className="page-item">
                              <div
                                className="page-link pagination-number"
                                href="#"
                                aria-label="Next"
                                onClick={() => onClickNext()}
                                style={{ borderRadius: "0 20px 20px 0" }}
                              >
                                <span aria-hidden="true">
                                  <i className="bi bi-chevron-compact-right"></i>
                                </span>
                              </div>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </NavBar>
    </>
  );
}

export default CustomizedItems;
