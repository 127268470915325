import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import api from "../../services/api";
import CurrencyInput from "react-currency-input-field";

function CustomItemModal({ show, onClose, data }) {
  const [itemName, setItemName] = useState("");
  const [billingType, setBillingType] = useState("");
  const [costValue, setCostValue] = useState("");
  const [saleValue, setSaleValue] = useState("");
  const [status, setStatus] = useState(true);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    toast.configure();
  }, []);

  useEffect(() => {
    if (!show) {
      setStatus(true)
      setItemName("");
      setBillingType("");
      setCostValue("");
      setSaleValue("");
      setStartTime("");
      setEndTime("");
      setQuantity(1);
      setErrors({});
    }
  }, [show]);

  useEffect(() => {
    const fetchItemDetails = async () => {
      if (data?.idToUpdate) {
        try {
          setIsSaving(true);
          const token = localStorage.getItem("token");
          const object = JSON.parse(token);
          const accessToken = object?.accessToken || "";
          const response = await api.get(
            `api/suporte/GetCustomItem?id=${data.idToUpdate}`,
            {
              headers: { Authorization: "Bearer " + accessToken },
            }
          );

          if (response.data) {
            const item = response.data;
            const billingTypeMap = {
              "Cupom Fiscal": "P",
              "Nota Fiscal": "S",
              Recibo: "L",
            };

            setItemName(item.name || "");
            setStatus(item.active ?? true);
            setBillingType(billingTypeMap[item.billingTypeDesc] || "");
            setCostValue(
                item.costPriceDesc.replace("R$", "").replace(",", ".").trim()|| ""
            );
            setSaleValue(
                item.priceDesc.replace("R$", "").replace(",", ".").trim()|| ""
            );
            const [start, end] = item.timeRangeDesc.split(" - ");
            setStartTime(start || "");
            setEndTime(end || "");
            setQuantity(item.quantity || 1);
            setIsSaving(false);
          } else {
            setIsSaving(false);
            toast.error("Não foi possível carregar os detalhes do item.");
          }
        } catch (error) {
          setIsSaving(false);
          toast.error("Erro ao buscar detalhes do item.");
        }
      } else {
        setItemName("");
        setBillingType("");
        setCostValue("");
        setStatus(true);
        setSaleValue("");
        setStartTime("");
        setEndTime("");
        setQuantity(1);
      }
      setErrors({});
    };

    if (show) {
      fetchItemDetails();
    }
  }, [data?.idToUpdate, show]);

  useEffect(() => {
    const modalElement = document.getElementById("customItemModal");
    const modal = new window.bootstrap.Modal(modalElement);

    if (show) {
      modal.show();
    }

    const handleModalClose = () => {
      onClose();
    };

    modalElement.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, [show, onClose]);

  const validateFields = () => {
    const newErrors = {};

    if (!itemName || itemName.length < 4 || itemName.length > 40) {
      newErrors.itemName = "O nome do item deve ter entre 4 e 40 caracteres.";
    }

    if (!billingType) {
      newErrors.billingType = "O tipo de faturamento é obrigatório.";
    }

    if (!costValue || parseFloat(costValue) <= 0) {
      newErrors.costValue = "O valor de custo deve ser maior que zero.";
    }

    if (!saleValue || parseFloat(saleValue) <= parseFloat(costValue)) {
      newErrors.saleValue =
        "O valor de venda deve ser maior que o valor de custo.";
    }

    if (!startTime) {
      newErrors.startTime = "O horário de início é obrigatório.";
    }

    if (!endTime) {
      newErrors.endTime = "O horário de fim é obrigatório.";
    }

    if (startTime >= endTime) {
      newErrors.endTime =
        "O horário de fim deve ser maior que o horário de início.";
    }

    if (!quantity || parseInt(quantity, 10) < 1) {
      newErrors.quantity = "A quantidade deve ser maior ou igual a 1.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (isSaving) return;

    if (data?.idToUpdate) {
      const updatePayload = {
        items: [
          {
            id: data.idToUpdate,
            active: status,
          },
        ],
      };

      try {
        setIsSaving(true);
        const token = localStorage.getItem("token");
        const object = JSON.parse(token);
        const accessToken = object?.accessToken || "";

        const response = await api.put(
          "api/suporte/UpdateCustomItemsActive",
          updatePayload,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.error) {
          toast.error(response.data.message || "Erro ao atualizar o item.");
        } else {
          toast.success("Item atualizado com sucesso!");
          const modalElement = document.getElementById("customItemModal");
          const modal = window.bootstrap.Modal.getInstance(modalElement);
          modal.hide();
          onClose();
        }
      } catch (error) {
        toast.error("Erro ao conectar-se à API.");
      } finally {
        setIsSaving(false);
      }
    } else {
      if (!validateFields()) {
        toast.error("Por favor, corrija os erros nos campos destacados.");
        return;
      }

      const contract = {
        Name: itemName,
        BillingType: billingType,
        CostPrice: parseFloat(costValue.replace(",", ".")),
        Price: parseFloat(saleValue.replace(",", ".")),
        StartTime: startTime,
        FinishTime: endTime,
        Quantity: parseInt(quantity, 10),
      };

      try {
        setIsSaving(true);
        const token = localStorage.getItem("token");
        const object = JSON.parse(token);
        const accessToken = object?.accessToken || "";

        const response = await api.post(
          "api/suporte/CreateCustomItem",
          contract,
          {
            headers: { Authorization: "Bearer " + accessToken },
          }
        );

        if (response.data.error) {
          toast.error(response.data.message || "Ocorreu um erro ao salvar o item.");
        } else {
          toast.success("Item salvo com sucesso!");
          const modalElement = document.getElementById("customItemModal");
          const modal = window.bootstrap.Modal.getInstance(modalElement);
          modal.hide();
          onClose();
        }
      } catch (error) {
        toast.error("Erro ao conectar-se à API.");
      } finally {
        setIsSaving(false);
      }
    }
  };

  const generateTimeOptions = () => {
    const options = [];
    let hour = 6;
    let minute = 0;

    for (let i = 0; i < 48; i++) {
      const formattedHour = hour.toString().padStart(2, "0");
      const formattedMinute = minute.toString().padStart(2, "0");
      options.push(`${formattedHour}:${formattedMinute}`);

      minute += 30;
      if (minute === 60) {
        minute = 0;
        hour += 1;
      }
      if (hour === 24) {
        hour = 0;
      }
    }
    return options;
  };

  return (
    <div
      className="modal fade"
      id="customItemModal"
      tabIndex="-1"
      aria-labelledby="customModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="customModalLabel">
              {data.idToUpdate ? "Atualizar Item" : "Criar Item"}
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div className="modal-body">
            {isSaving && <div className="BCS-divisor-loader mt-4"></div>}
            <form>
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="itemName" className="form-label">
                    Nome
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errors.itemName ? "is-invalid" : ""}`}
                    id="itemName"
                    placeholder="Nome do item"
                    value={itemName}
                    maxLength={40}
                    onChange={(e) => setItemName(e.target.value)}
                    disabled={isSaving || data.idToUpdate !== null}
                  />
                  <div className="invalid-feedback">{errors.itemName}</div>
                </div>
                <div className="col">
                  <label htmlFor="billingType" className="form-label">
                    Tipo de faturamento
                  </label>
                  <select
                    className={`form-select ${errors.billingType ? "is-invalid" : ""}`}
                    id="billingType"
                    value={billingType}
                    onChange={(e) => setBillingType(e.target.value)}
                    disabled={isSaving || data.idToUpdate !== null}
                  >
                    <option value="">Selecione um tipo</option>
                    <option value="P">Cupom Fiscal</option>
                    <option value="S">Nota Fiscal</option>
                    <option value="L">Recibo</option>
                  </select>
                  <div className="invalid-feedback">{errors.billingType}</div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="costValue" className="form-label">
                    Valor de custo (R$)
                  </label>
                  <CurrencyInput
                    id="costValue"
                    name="costValue"
                    placeholder="0,00"
                    value={costValue}
                    decimalsLimit={2}
                    decimalSeparator=","
                    groupSeparator="."
                    prefix="R$ "
                    disabled={isSaving || data.idToUpdate !== null}
                    onValueChange={(value) => setCostValue(value)}
                    className="form-control"
                  />
                  <div className="invalid-feedback">{errors.costValue}</div>
                </div>
                <div className="col">
                  <label htmlFor="saleValue" className="form-label">
                    Valor de venda (R$)
                  </label>
                  <CurrencyInput
                        id="saleValue"
                        name="saleValue"
                        placeholder="0,00"
                        value={saleValue}
                        decimalsLimit={2}
                        decimalSeparator=","
                        groupSeparator="."
                        prefix="R$ "
                        disabled={isSaving || data.idToUpdate !== null}
                        onValueChange={(value) => setSaleValue(value)}
                        className="form-control"
                        />
                  <div className="invalid-feedback">{errors.saleValue}</div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="startTime" className="form-label">
                    Horário
                  </label>
                  <div className="d-flex">
                    <select
                      className={`form-select me-2 ${errors.startTime ? "is-invalid" : ""}`}
                      id="startTime"
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                      disabled={isSaving || data.idToUpdate !== null}
                    >
                      <option value="">Início</option>
                      {generateTimeOptions().map((time) => (
                        <option key={`start-${time}`} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                    <select
                      className={`form-select ${errors.endTime ? "is-invalid" : ""}`}
                      id="endTime"
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                      disabled={isSaving || data.idToUpdate !== null}
                    >
                      <option value="">Fim</option>
                      {generateTimeOptions().map((time) => (
                        <option key={`end-${time}`} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="invalid-feedback">{errors.endTime}</div>
                </div>
                <div className="col">
                  <label htmlFor="quantity" className="form-label">
                    Quantidade
                  </label>
                  <input
                    type="number"
                    className={`form-control ${errors.quantity ? "is-invalid" : ""}`}
                    id="quantity"
                    value={quantity}
                    min="1"
                    onChange={(e) => setQuantity(e.target.value)}
                    disabled={isSaving || data.idToUpdate !== null}
                  />
                  <div className="invalid-feedback">{errors.quantity}</div>
                </div>
              </div>
              {data.idToUpdate && (
                <div className="row mb-3">
                    <div className="col">
                        <label htmlFor="status" className="form-label">
                        Status do Item
                        </label>
                        <select
                        id="status"
                        className="form-select"
                        value={status ? "1" : "0"}
                        onChange={(e) => setStatus(e.target.value === "1")}
                        disabled={isSaving}
                        >
                        <option value="1">Ativo</option>
                        <option value="0">Inativo</option>
                        </select>
                    </div>
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer justify-content-around">
            <button
              type="button"
              className="btn button-secondary-appito"
              data-bs-dismiss="modal"
              disabled={isSaving}
            >
              Fechar
            </button>
            <button
              type="button"
              className="btn button-primary-appito"
              onClick={handleSave}
              disabled={isSaving}
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomItemModal;
