import React, { useState, useEffect } from "react";

// mensagens de erro
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";

import "../../style/global.css";
import Button from "../../components/Button";

import api from "../../services/api";

import mask from "../../util/mask";

import ListManager from "../../components/ListManager";

import { accessValidator } from "../../util/validationProfile";

function Sms() {
  let history = useHistory();

  //States
  const [phone, setPhone] = useState("");
  const [phoneValidator, setPhoneValidator] = useState(false);

  const [registrationCodes, setRegistrationCodes] = useState([]);
  const [registrationValidator, setRegistrationValidator] = useState(false);

  const [accessCodes, setAccessCodes] = useState([]);
  const [accessValidate, setAccessValidate] = useState(false);

  const [countryNumber, setCountryNumber] = useState("+55");
  const [countryNumberValidator, setCountryNumberValidator] = useState(false);

  const [sendMessage, setSendMessage] = useState(false);

  function handleCancel() {
    history.push("/home");
  }

  const handleClickClipboardRegistration = () => {
    try {
      let message = "Código de Cadastro:";
      registrationCodes.map((code) => {
        message += `\n${code} `;
      });

      // tentativa 1
      // navigator.clipboard.writeText(message);

      // tentativa 2
      const textarea = document.createElement("textarea");
      document.body.appendChild(textarea);
      textarea.value = message;
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      toast.success("Código de cadastro copiado com sucesso");
    } catch (error) {
      toast.success("Erro ao copiar códigos");
    }
  };

  const handleClickClipboardAccessCodes = () => {
    try {
      let message = "Código de Acesso:";
      accessCodes.map((code) => {
        message += `\n${code} `;
      });

      // tentativa 1
      // navigator.clipboard.writeText(message);

      // tentativa 2
      const textarea = document.createElement("textarea");
      document.body.appendChild(textarea);
      textarea.value = message;
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      toast.success("Código de acesso copiado com sucesso");
    } catch (error) {
      toast.success("Erro ao copiar códigos");
    }
  };

  //   const handleClickClipBoth = () => {
  //     const message = `id: ${returnId}

  // Codigo: ${codigo}

  // Link: ${link}

  // Obrigado por comprar com Appito
  //       `;
  // navigator.clipboard.writeText(message);
  // toast.success('Código copiado com sucesso');
  // };

  async function dataValidator() {
    let validatorImpedimento = true;

    if (phone === "") {
      setPhoneValidator(true);
      setCountryNumberValidator(true);
      validatorImpedimento = false;
    } else {
      setPhoneValidator(false);
      setCountryNumberValidator(false);
    }

    return validatorImpedimento;
  }

  const removeCharsPhone = (characteres) => {
    // NOTAGAB: Dani pediu para remover dia 16-05-2023 14:06 - rafa precisa dos numeros com zero e internacionais
    let newPhone = mask.maskPhone(characteres);
    setPhone(newPhone);
  };

  const mockListCode = () => {
    setRegistrationValidator(false);
    const arrayMockRegistration = [];

    setAccessValidate(true);
    const arrayMockAccess = [1958, 1962, 1970, 1994, 2002];

    setRegistrationCodes(arrayMockRegistration);
    setAccessCodes(arrayMockAccess);

    setSendMessage(true);
  };

  const handleClickSMS = async () => {
    const token = localStorage.getItem("token");
    const object = JSON.parse(token);

    let validador = true;
    validador = await dataValidator();
    if (validador === false) {
      toast.error("Campo em vermelho precisa ser preenchido corretamente");
      return;
    }

    const newPhone = mask.clearPhone(phone);

    const completePhone = countryNumber.replace("+", "%2B") + newPhone;

    let accessToken = "";
    if (object.accessToken) {
      accessToken = object.accessToken;
    }

    const response = await api.get(
      `api/suporte/GetCodesByPhone?Phone=${completePhone}`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );

    if (response.data.onboardingCodes.length > 0) {
      setRegistrationValidator(true);
    } else {
      setRegistrationValidator(false);
    }

    setRegistrationCodes(response.data.onboardingCodes);

    if (response.data.accessCodes.length > 0) {
      setAccessValidate(true);
    } else {
      setAccessValidate(false);
    }
    setAccessCodes(response.data.accessCodes);

    setSendMessage(true);

    // mockListCode()
  };

  const validate = () => {
    const verify = accessValidator("sms");

    if (verify === false) {
      toast.info(
        "Seu usuário não tem permissões para esse sistema, contate o suporte"
      );
      history.push("/home");
    }
  };

  useEffect(() => {
    validate();
  }, []);

  return (
    <section>
      <div className="container ">
        <div className="row justify-content-center ">
          <div className="col-12 col-md-12 col-lg-10 col-xl-10 text-center ">
            <div className="col">
              <p className="text-title-Appito justify-content-center pt-4 tracking-in-expand ">
                SMS
              </p>
            </div>

            <div className="row">
              <small id="Telefone" className="form-text text-muted">
                Telefone
              </small>
              <div className="col-12 d-flex justify-content-center">
                <div className="col-3 col-sm-2 col-lg-1 m-1">
                  <div className="col">
                    <input
                      type="text"
                      className={`form-control 
                    ${
                      phoneValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                    scale-in-center `}
                      placeholder="+55"
                      value={countryNumber}
                      onChange={(event) => setCountryNumber(event.target.value)}
                    />
                  </div>
                </div>

                <div className="col-8  col-sm-4 col-md-3 m-1">
                  <input
                    type="text"
                    className={`form-control 
                    ${
                      phoneValidator === false
                        ? "input-appito"
                        : "input-appito-error"
                    }
                    scale-in-center `}
                    placeholder="Ex: (99)99999-9999"
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </div>
              </div>
            </div>
            <div>
              <Button
                text=" OK"
                typeButton="primary"
                onClick={() => handleClickSMS()}
                style="mb-2 col-4 col-sm-2 col-lg-1  text-center scale-in-center "
              />
            </div>

            <div
              className={`
              row d-flex justify-content-around mt-3
                ${sendMessage === true ? "" : "d-none"}
              `}
            >
              <div className={`col-10 col-sm-5 `}>
                <h5>Códigos de Cadastro</h5>
                <div
                  className={`${
                    registrationValidator === true ? "" : "d-none"
                  } `}
                >
                  {registrationCodes.map((code) => (
                    <ListManager key={code} code={code} />
                  ))}

                  <button
                    className="btn button-secundary-appito col-4 scale-in-center mt-1 mb-1 "
                    type="button"
                    onClick={() => handleClickClipboardRegistration()}
                  >
                    Copiar
                  </button>
                </div>

                <div
                  className={`${
                    registrationValidator === false ? "" : "d-none"
                  } `}
                >
                  <div>
                    <p style={{ color: "#ff0000" }}>Código não encontrado</p>
                  </div>
                </div>
              </div>

              <div className={`col-10 col-sm-5 `}>
                <h5>Códigos de Acesso</h5>

                <div className={`${accessValidate === true ? "" : "d-none"} `}>
                  {accessCodes.map((code) => (
                    <ListManager key={code} code={code} />
                  ))}

                  <button
                    className="btn button-secundary-appito col-4 scale-in-center mt-1 mb-1"
                    type="button"
                    onClick={() => handleClickClipboardAccessCodes()}
                  >
                    Copiar
                  </button>
                </div>

                <div className={`${accessValidate === false ? "" : "d-none"} `}>
                  <div>
                    <p style={{ color: "#ff0000" }}>Código não encontrado</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sms;
